import React from "react";
import './App.css';
import MyHeader from "./components/layouts/MyHeader";
import MyFooter from "./components/layouts/MyFooter";
import Myroutes from "./routes/Myroutes";
function App() {
  return (
    <div className="App">
      <MyHeader/>
      <div className="prize_user_Content">
        <div className="">
        <Myroutes/>
        </div>
      </div>
      <MyFooter/>
    </div>
  );
}

export default App;
