import React from "react";

const MyFooter = () => {
    return (
      <div>
        <footer className="text-center text-lg-start">
          <div className="text-center p-3">جميع الحقوق محفوظة 2024</div>
        </footer>
      </div>
    );
};

export default MyFooter;
