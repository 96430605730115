import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logoC from "../../assets/logo2.png";
import "./layout.css";

const MyHeader = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(null);
  const [notifications, setNotifications] = useState({ new: [], old: [] });
  useEffect(() => {
    const clientId = localStorage.getItem("user_id");

    if (clientId) {
      axios
        .get(`https://te.prize.pap.sa/api/client/${clientId}`)
        .then((response) => {
          // console.log(response.data.client_data.step_id);
          setStep(response.data.client_data.step_id);
        })
        .catch((error) => {
          console.error("Error fetching client data:", error);
        });
    }

    const fetchNotifications = async () => {
      try {
        const clientId = localStorage.getItem("user_id");
        if (!clientId) {
          console.error("Client ID not found in local storage.");
          return;
        }

        const url = `https://te.prize.pap.sa/api/new/notifications?user_id=${clientId}`;
        const response = await axios.post(url);

        console.log(response.data);

        if (Array.isArray(response.data.new_notifications)) {
          setNotifications({
            new: response.data.new_notifications,
            old: response.data.old_notifications,
          });
        } else {
          console.error(
            "Expected an array for new notifications, received:",
            response.data.new_notifications
          );
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, []);

  // Check user login status from localStorage
  const userLogin = localStorage.getItem("user_login") === "true";

  // Function to handle logout
  const handleLogout = () => {
    // Remove relevant items from local storage
    localStorage.removeItem("client_id");
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_type");
    localStorage.removeItem("user_login");

    // Other items to remove, if any
    // Navigate to the desired path
    navigate("/");
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img src={logoC} alt="" className="prize_user_logo" />
          </NavLink>
          <button
            className="navbar-toggler white-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <div className="prize_user_links">
              <ul className="navbar-nav">
                {userLogin && (
                  <>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/userprofile"
                        activeClassName="active"
                      >
                        <i className="fa-solid fa-house"></i> الرئيسية
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/info"
                        activeClassName="active"
                      >
                        <i className="fa-regular fa-user"></i> معلوماتي الشخصية
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/business"
                        activeClassName="active"
                      >
                        <i className="fa-regular fa-user"></i> معلوماتي التجارية
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/account"
                        activeClassName="active"
                      >
                        <i className="fa-regular fa-user"></i> حسابي
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/support"
                        activeClassName="active"
                      >
                        <i className="fa-regular fa-flag"></i> الدعم الفني
                      </NavLink>
                    </li>

                    <li className="nav-item dropdown">
                      <div className="notification-dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to="/notification"
                          id="navbarDropdownMenuLink"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa-regular fa-bell fs-4"></i>
                          {notifications.new.length > 0 && (
                            <span className="badge bg-danger">
                              {notifications.new.length}
                            </span>
                          )}
                        </Link>
                        <ul
                          className="dropdown-menu notification-menu"
                          aria-labelledby="navbarDropdownMenuLink"
                        >
                          {notifications.new.map((notification, index) => (
                            <li key={index}>
                              <Link
                                className="dropdown-item notification-item"
                                to={`/notification/${notification.id}`}
                              >
                                <div className="notification-action">
                                  {notification.action}
                                </div>
                                <div className="notification-time">
                                  {new Date(
                                    notification.created_at
                                  ).toLocaleString()}
                                </div>
                              </Link>
                            </li>
                          ))}
                          <li className="notification-link">
                            <Link
                              to="/notification"
                              className="user_notification_link"
                            >
                              الإشعارات
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="nav-item">
                      <button
                        className="nav-link"
                        onClick={handleLogout}
                        style={{
                          border: "none",
                          background: "none",
                          cursor: "pointer",
                        }}
                      >
                        <i className="fa-solid fa-xmark"></i> خروج
                      </button>
                    </li>
                  </>
                )}
                {!userLogin && (
                  <>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/register"
                        activeClassName="active"
                      >
                        <i className="fa-solid fa-user-plus"></i> إنشاء حساب
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/"
                        activeClassName="active"
                      >
                        <i className="fa-regular fa-user"></i> تسجيل الدخول
                      </NavLink>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {userLogin && step > 2 && (
        <section className="userConfirm">
          <div className="container">
            <div className="row">
              <div className="d-flex align-items-center">
                <span className="user_icon_info mx-2">
                  <i className="fa-solid fa-info"></i>
                </span>
                <div className="user_content_confirm text-center">
                  لتتمكن من تعديل ملفك الشخصي أو التجاري يرجى التواصل مع
                </div>
                <NavLink
                  to="/support"
                  className="mx-2 user_support"
                  activeClassName="active"
                >
                  الدعم الفني
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default MyHeader;
