import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Card,
} from "@mui/material";
import companyLogo from "../../assets/logo.png";

const VerificationReset = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [phone, setPhone] = useState("");
  const [page, setPage] = useState("");
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const phoneFromParams = searchParams.get("phone");

    const pageParams = location.state?.page;
    if (pageParams) {
      setPage(pageParams);
    } else {
      setPage(1);
    }
    if (phoneFromParams) {
      setPhone(phoneFromParams);
    } else if (location.state && location.state.phone) {
      setPhone(location.state.phone);
    }
  }, [location.search, location.state]);

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://te.prize.pap.sa/api/verify/code",
        {
          phone: phone,
          verification_code: verificationCode,
        }
      );

      toast.success("تم التحقق بنجاح", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      if (page == 2) {
        console.log(page);
        navigate("/reset_password", { state: { phone: phone } });
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Verification Error:", error.response.data);

      if (error.response && error.response.data.error) {
        setErrors(error.response.data.error);
      } else {
        setErrors({ general: "An error occurred. Please try again later." });
      }

      toast.error("فشل التحقق. يرجى المحاولة مرة اخري.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div>
      <ToastContainer />
      <Container component="main" maxWidth="xs" style={{ paddingTop: "80px" }}>
        <Card
          style={{
            boxShadow: "0 4px 8px 0 rgba(0, 128, 0, 0.2)",
            padding: "20px",
            width: "100%",
          }}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              animation: "widthAnimation 1s ease-out forwards",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "80%",
            }}
          >
            <img src={companyLogo} alt="" width="100px" className="mb-3" />
            <Typography
              component="h2"
              variant="h5"
              sx={{ color: "#198754", fontWeight: "bold" }}
            >
              التحقق من رقم الجوال
            </Typography>
            <Box
              component="form"
              onSubmit={handleVerificationSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                fullWidth
                id="verificationCode"
                label="أدخل رمز التحقق"
                name="verificationCode"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                classes={{ focused: "focused" }}
                error={!!errors.verificationCode}
                helperText={errors.verificationCode}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "rgb(0, 166, 147)",
                  "&:hover": { backgroundColor: "rgb(0, 150, 130)" },
                }}
              >
                تسجيل
              </Button>
            </Box>
          </Box>
        </Card>
      </Container>
    </div>
  );
};

export default VerificationReset;
