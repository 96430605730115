import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import VerificationReset from "../pages/veryfication/VerificationReset";

// Replace this with your actual authentication logic
const isUserLoggedIn = () => {
  const userLogin = localStorage.getItem("user_login");
  return userLogin === "true";
};

const PrivateRoute = ({ element }) => {
  if (!isUserLoggedIn()) {
    // Redirect to the login page if the user is not logged in
    return <Navigate to="/" replace />;
  }

  return element;
};

const PrivateRoute2 = ({ element }) => {
  if (isUserLoggedIn()) {
    // Redirect to the login page if the user is not logged in
    return <Navigate to="/userprofile" replace />;
  }

  return element;
};

const Login = lazy(() => import("../pages/auth/Login"));
const Register = lazy(() => import("../pages/auth/Register"));
const UserProfile = lazy(() => import("../pages/UserProfile"));
const MyAccount = lazy(() => import("../pages/account/MyAccount"));
const Info = lazy(() => import("../pages/Info/Info"));
const Business = lazy(() => import("../pages/Business/Business"));
const Support = lazy(() => import("../pages/support/Support"));
const Verification = lazy(() => import("../pages/veryfication/Verification"));
const ResetPassword = lazy(() => import("../pages/restPassword/ResetPassword"));
const NotFound = lazy(() => import("../pages/NotFound"));
const Notification = lazy(() => import("../pages/Notification/Notification"));

const SkeletonLoader = () => (
  <div>
    <Skeleton height={50} />
    <Skeleton count={5} />
  </div>
);

const MyRoutes = () => {
  return (
    <Suspense fallback={<SkeletonLoader />}>
      <Routes>
        <Route path="/" element={<PrivateRoute2 element={<Login />} />} />
        <Route path="/register/" element={<PrivateRoute2 element={<Register />} />} />
        <Route path="/verification/" element={<PrivateRoute2 element={<Verification />} />} />
        <Route path="/verification/reset/password" element={<PrivateRoute2 element={<VerificationReset />} />} />

        <Route path="/reset_password/" element={<PrivateRoute2 element={<ResetPassword />} />} />

        {/* Public routes accessible to all */}
        <Route path="/not-found" element={<NotFound />} />

        {/* Private routes accessible only if the user is logged in */}
        <Route path="/userprofile/" element={<PrivateRoute element={<UserProfile />} />} />
        <Route path="/account/" element={<PrivateRoute element={<MyAccount />} />} />
        <Route path="/info/" element={<PrivateRoute element={<Info />} />} />
        <Route path="/business/" element={<PrivateRoute element={<Business />} />} />
        <Route path="/support/" element={<PrivateRoute element={<Support />} />} />
        <Route path="/notification/" element={<PrivateRoute element={<Notification />} />} />
        <Route path="/notification/:notificationId" element={<PrivateRoute element={<Notification />} />} />

        {/* Redirect to Not Found for any unmatched route */}
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Suspense>
  );
};

export default MyRoutes;

